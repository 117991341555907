// Chakra imports
import { Badge, Button, Flex, Link, Text, useColorModeValue } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { Fn } from "services/functions";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import ActivityLog from "./components/ActivityLog";
import ActivityDetails from "./components/ActivityDetails";
import moment from "moment";
import { LinkIcon } from "@chakra-ui/icons";

function Activity() {
  const [activity, setActivity] = React.useState([]);

  const { id } = useParams();

  useEffect(() => {
    Fn.activity(id).then((res) => {
      console.log("activity", res.data);
      setActivity(res.data);
    });

  }, []);


  let textColor = useColorModeValue("gray.700", "white");
  let statusTextColor = useColorModeValue("gray.700", "white");
  let statusColor = useColorModeValue("gray.400", "gray.400");

  if (activity?.status === "approved") {
    statusColor = useColorModeValue("green.400", "gray.400");
    statusTextColor = useColorModeValue("white", "white");
  } else if (activity?.status === "pending") {
    statusColor = useColorModeValue("yellow.400", "gray.400");
    statusTextColor = useColorModeValue("white", "white");
  } else if (activity?.status === "rejected") {
    statusColor = useColorModeValue("red.400", "gray.400");
    statusTextColor = useColorModeValue("white", "white");
  } else if (activity?.status === "denied") {
    statusColor = useColorModeValue("red.400", "gray.400");
    statusTextColor = useColorModeValue("white", "white");
  }



  return (
    <Flex direction='column' pt={{ base: "75px", md: "15px" }}>
      <Card my='22px' overflowX={{ sm: "scroll", xl: "hidden" }}>
        <CardHeader p='6px 0px 22px 0px'>
          <Flex direction='column' flex={1}>
            <Text fontSize='lg' fontWeight='bold' pb='.5rem'>
              {activity?.title}
            </Text>
            <Text fontSize='sm' color='gray.500'>
              {activity?.id}
            </Text>
          </Flex>
          <Flex>
            <Button leftIcon={<LinkIcon />}>Open In Credible</Button>
          </Flex>
        </CardHeader>
        <CardBody>
          <Flex style={{ flex: 1 }}>
            <div style={{ flex: 1 }}>
              <Text fontSize='lg' fontWeight='bold' pb='.5rem'>Note</Text>
              {activity?.note}
            </div>

          </Flex>
          <Flex style={{ flex: 1 }}>
            <div style={{ flex: 1 }}>
              <Text fontSize='lg' fontWeight='bold' pb='.5rem'>Created At</Text>
              {moment(activity?.created_at).format("D/M/YY  HH:mm:ss A")}
            </div>

          </Flex>
          <Flex style={{ flex: 1 }}>
            <div style={{ flex: 1 }}>
              <Text fontSize='lg' fontWeight='bold' pb='.5rem'>Status</Text>

              <Badge
                bg={statusColor}
                color={statusTextColor}
                fontSize="16px"
                p="3px 10px"
                borderRadius="8px"
              >
                {activity?.status}
              </Badge>
            </div>
          </Flex>

        </CardBody>
      </Card>
      <Flex>
        <div style={{
          paddingRight: 10,
          flex: 1
        }}>
          <ActivityDetails
            title={"Activity"}
            captions={["Activity", "Date", "Status"]}
            data={activity}
            id={id}
          /></div>
        <div style={{
          paddingLeft: 10,
          flex: 1
        }}>
          <ActivityLog
            title={"Activity"}
            captions={["Log", "Date", "Status"]}
            data={activity}
            id={id}
          /></div></Flex>
    </Flex>
  );
}

export default Activity;
