import React, { useEffect } from "react";
import {
  Tr,
  Td,
  Flex,
  Text,
  Progress,
  Icon,
  Button,
  useColorModeValue,
  Badge,
  Avatar,
} from "@chakra-ui/react";
import { FaEllipsisV } from "react-icons/fa";
import moment from "moment";
import { Bucket } from "services/storage";

function DashboardTableRow(props) {
  const { logo, name, status, date, id, provider } = props;

  const [image, setImage] = React.useState(null);

  let textColor = useColorModeValue("gray.700", "white");
  let statusTextColor = useColorModeValue("gray.700", "white");
  let statusColor = useColorModeValue("gray.400", "gray.400");

  if (status === "approved") {
    statusColor = useColorModeValue("green.400", "gray.400");
    statusTextColor = useColorModeValue("white", "white");
  } else if (status === "pending") {
    statusColor = useColorModeValue("yellow.400", "gray.400");
    statusTextColor = useColorModeValue("white", "white");
  } else if (status === "rejected") {
    statusColor = useColorModeValue("red.400", "gray.400");
    statusTextColor = useColorModeValue("white", "white");
  } else if (status === "denied") {
    statusColor = useColorModeValue("red.400", "gray.400");
    statusTextColor = useColorModeValue("white", "white");
  }

  useEffect(() => {
    Bucket.getUrl(logo).then((url) => {
      console.log("url", url);
      setImage(url);
    });
  }, []);

  return (
    <Tr onClick={() => {
      window.location.href = `#/admin/activity/` + id;
    }}>
      <Td minWidth={{ sm: "250px" }} pl="0px">
        <Flex alignItems="center" minWidth="100%" flexWrap="nowrap">
          <Avatar src={image} w="50px" h="50px" borderRadius="12px" me="18px" />
          <Flex direction="column">
            <Text
              fontSize="md"
              color={textColor}
              fontWeight="bold"
              minWidth="100%"

            >
              {name}
            </Text>
            <Text fontSize="sm" color="teal.400" fontWeight="bold"

            >
              ID: {id}
            </Text>
            <Text fontSize="sm" color="teal.400" fontWeight="bold"

            >
              {provider}
            </Text>
          </Flex>
        </Flex>
      </Td>
      <Td >
        <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
          {moment(date).format("lll")}
        </Text>
      </Td>
      <Td >
        <Badge
          bg={statusColor}
          color={statusTextColor}
          fontSize="16px"
          p="3px 10px"
          borderRadius="8px"
        >
          {status}
        </Badge>
      </Td>

      {/* <Td>
        <Button p="0px" bg="transparent">
          <Icon as={FaEllipsisV} color="gray.400" cursor="pointer" />
        </Button>
      </Td> */}
    </Tr>
  );
}

export default DashboardTableRow;
