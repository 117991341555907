import React, { useEffect } from "react";
import {
  Tr,
  Td,
  Flex,
  Text,
  Progress,
  Icon,
  Button,
  useColorModeValue,
  Badge,
  Avatar,
} from "@chakra-ui/react";
import { FaEllipsisV } from "react-icons/fa";
import moment from "moment";
import { Bucket } from "services/storage";

function TablesLogRow(props) {
  const { log } = props;


  let textColor = useColorModeValue("gray.700", "white");
  let statusTextColor = useColorModeValue("gray.700", "white");
  let statusColor = useColorModeValue("gray.400", "gray.400");

  if (log.status === "approved") {
    statusColor = useColorModeValue("green.400", "gray.400");
    statusTextColor = useColorModeValue("white", "white");
  } else if (log.status === "pending") {
    statusColor = useColorModeValue("yellow.400", "gray.400");
    statusTextColor = useColorModeValue("white", "white");
  } else if (log.status === "rejected") {
    statusColor = useColorModeValue("red.400", "gray.400");
    statusTextColor = useColorModeValue("white", "white");
  } else if (log.status === "denied") {
    statusColor = useColorModeValue("red.400", "gray.400");
    statusTextColor = useColorModeValue("white", "white");
  }



  return (
    <Tr >
      <Td minWidth={{ sm: "100px" }} pl="0px">
        <Flex alignItems="center" minWidth="100%" flexWrap="nowrap">
          <Flex direction="column">
            <Text
              fontSize="md"
              color={textColor}
              fontWeight="bold"
              minWidth="100%"

            >
              {log.title}
            </Text>
            <Text fontSize="sm" color="teal.400" fontWeight="bold"

            >
              LOG#: {log.id}
            </Text>
            <Text fontSize="sm" color="teal.400" fontWeight="bold"

            >
              {log.note}
            </Text>
          </Flex>
        </Flex>
      </Td>
      <Td >
        <Text fontSize="sm" color={textColor} fontWeight="bold" pb="1rem">
          {moment(log.created_at).format("MMMM Do YYYY, h:mm:ss a")}
        </Text>
      </Td>
      <Td >
        <Badge
          bg={statusColor}
          color={statusTextColor}
          fontSize="16px"
          p="3px 10px"
          borderRadius="8px"
        >
          {log.status}
        </Badge>
      </Td>

      {/* <Td>
        <Button p="0px" bg="transparent">
          <Icon as={FaEllipsisV} color="gray.400" cursor="pointer" />
        </Button>
      </Td> */}
    </Tr>
  );
}

export default TablesLogRow;
