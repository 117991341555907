// Chakra imports
import { ChakraProvider, Portal, useDisclosure } from '@chakra-ui/react';
import Configurator from 'components/Configurator/Configurator';
import Footer from 'components/Footer/Footer.js';
// Layout components
import AdminNavbar from 'components/Navbars/AdminNavbar.js';
import Sidebar from 'components/Sidebar';
import React, { useEffect, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import routes from 'routes.js';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
// Custom Chakra theme
import theme from 'theme/theme.js';
import FixedPlugin from '../components/FixedPlugin/FixedPlugin';
// Custom components
import MainPanel from '../components/Layout/MainPanel';
import PanelContainer from '../components/Layout/PanelContainer';
import PanelContent from '../components/Layout/PanelContent';
import Db from 'services/db';
import { useRecoilState } from 'recoil';
import { companyState } from 'services/states';
import { userState } from 'services/states';
import { Fn } from 'services/functions';
import { teamState } from 'services/states';
import { set } from 'lodash';
import { profileState } from 'services/states';
import { Bucket } from 'services/storage';
import { profileImageState } from 'services/states';
import Setup from 'views/Setup';
export default function Dashboard(props) {
	const { ...rest } = props;
	// states and functions
	const [sidebarVariant, setSidebarVariant] = useState('transparent');
	const [fixed, setFixed] = useState(false);
	const [loading, setLoading] = useState(true);
	const [noCompanies, setNoCompanies] = useState(false);
	const [company, setCompany] = useRecoilState(companyState);
	const [user, setUser] = useRecoilState(userState);
	const [team, setTeam] = useRecoilState(teamState);
	const [profile, setProfile] = useRecoilState(profileState);
	const [profileImage, setProfileImage] = useRecoilState(profileImageState);

	// get user 
	useEffect(() => {
		Db.user.verify().then((res) => {
			if (!res.data.session || !res.data.session.access_token) {
				window.location.href = "#/auth/signin";
			} else {
				console.log("signed in 🎉");


				//TODO: remove after testing 
				Fn.team().then((t) => {
					console.log(t);
					t.data && t.data.members && setTeam(t.data.members);
				});

				// get user
				Db.user.getUser().then(async (u) => {
					let p = await Db.user.getProfile();
					let user = u.data.user;
					user.profile = p;
					setUser(u.data.user);
					console.log(p);
					setProfile(p);
					if (p && p?.image) {
						Bucket.getUrl(p.image).then((url) => {
							setProfileImage(url);
						});
					}

				});

				// get company
				Db.companies.getCompanies().then((x) => {
					if (x.length === 0) {
						setNoCompanies(true);
						setLoading(false);
					} else {
						Db.companies.getCompany(x[0].company_id).then((c) => {
							if (c.length === 0) {
								setNoCompanies(true);
								setLoading(false);
							} else {
								setCompany(c[0]);
								setLoading(false);
							}
						});
						if (x[0].status != "accepted") {
							Db.companies.acceptCompany(x[0].company_id).then((y) => {
							}
							);
						}
					}
				}
				);
			}
		});
	}, []);

	// functions for changing the states from components
	const getRoute = () => {
		return window.location.pathname !== '/admin/full-screen-maps';
	};
	const getActiveRoute = (routes) => {
		let activeRoute = 'Default Brand Text';
		for (let i = 0; i < routes.length; i++) {
			if (routes[i].collapse) {
				let collapseActiveRoute = getActiveRoute(routes[i].views);
				if (collapseActiveRoute !== activeRoute) {
					return collapseActiveRoute;
				}
			} else if (routes[i].category) {
				let categoryActiveRoute = getActiveRoute(routes[i].views);
				if (categoryActiveRoute !== activeRoute) {
					return categoryActiveRoute;
				}
			} else {
				if (window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1) {
					return routes[i].name;
				}
			}
		}
		return activeRoute;
	};
	// This changes navbar state(fixed or not)
	const getActiveNavbar = (routes) => {
		let activeNavbar = false;
		for (let i = 0; i < routes.length; i++) {
			if (routes[i].category) {
				let categoryActiveNavbar = getActiveNavbar(routes[i].views);
				if (categoryActiveNavbar !== activeNavbar) {
					return categoryActiveNavbar;
				}
			} else {
				if (window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1) {
					if (routes[i].secondaryNavbar) {
						return routes[i].secondaryNavbar;
					}
				}
			}
		}
		return activeNavbar;
	};
	const getRoutes = (routes) => {
		return routes.map((prop, key) => {
			if (prop.collapse) {
				return getRoutes(prop.views);
			}
			if (prop.category === 'account') {
				return getRoutes(prop.views);
			}
			if (prop.layout === '/admin') {
				return <Route path={prop.layout + prop.path} component={prop.component} key={key} />;
			} else {
				return null;
			}
		});
	};
	const { isOpen, onOpen, onClose } = useDisclosure();
	document.documentElement.dir = 'ltr';
	// Chakra Color Mode
	return (
		<>
			{loading ? (<div style={{ backgroundColor: "#F7FAFC" }}></div>) : (<ChakraProvider theme={theme} resetCss={false}>
				<Sidebar
					routes={routes}
					logoText={'VALID DASHBOARD'}
					display='none'
					sidebarVariant={sidebarVariant}
					{...rest}
				/>
				<MainPanel
					w={{
						base: '100%',
						xl: 'calc(100% - 275px)'
					}}>

					<Portal>
						<AdminNavbar
							onOpen={onOpen}
							logoText={'VALID DASHBOARD'}
							brandText={getActiveRoute(routes)}
							secondary={getActiveNavbar(routes)}
							fixed={fixed}
							{...rest}
						/>
					</Portal>
					{getRoute() ? (
						<PanelContent>
							<PanelContainer>
								{company ? (<Switch>

									{getRoutes(routes)}
									<Redirect from='/admin' to='/admin/dashboard' />
								</Switch>) : (
									<Switch>
										{!loading && (
											<>
												<Route path={"/"} component={Setup} />
												<Redirect from='/admin' to='/admin/dashboard' />
											</>
										)}
									</Switch>
								)}
							</PanelContainer>
						</PanelContent>
					) : null}
					<Footer />

				</MainPanel>
			</ChakraProvider>)}</>
	);
}
