import { atom } from "recoil";

// company state
export const companyState = atom({
    key: 'companyState', // unique ID (with respect to other atoms/selectors)
    default: null, // default value (aka initial value)
});

// user state
export const userState = atom({
    key: 'userState', // unique ID (with respect to other atoms/selectors)
    default: null, // default value (aka initial value)
});

// team state
export const teamState = atom({
    key: 'teamState', // unique ID (with respect to other atoms/selectors)
    default: null, // default value (aka initial value)
});

// profileState
export const profileState = atom({
    key: 'profileState', // unique ID (with respect to other atoms/selectors)
    default: null, // default value (aka initial value)
});

// profileImageState
export const profileImageState = atom({
    key: 'profileImageState', // unique ID (with respect to other atoms/selectors)
    default: null, // default value (aka initial value)
});