// Chakra imports
import {
  Avatar,
  Button,
  Flex,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

// Custom components
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import { functions } from "lodash";
import React, { useEffect } from "react";
import { useRecoilState } from "recoil";
import { teamState } from "services/states";
import { Bucket } from "services/storage";

function TeamMember(props) {
  const [image, setImage] = React.useState();
  // Chakra color mode
  const textColor = useColorModeValue("gray.700", "white");

  useEffect(() => {
    console.log(props);
    Bucket.getUrl(props?.image).then((url) => {
      setImage(url);
    });
  }, [props]);

  return (<Flex justifyContent='space-between' mb='21px'>
    <Flex align='center'>
      {props.image && (
        <Avatar
          src={image}
          w='50px'
          h='50px'
          borderRadius='15px'
          me='10px'
        />)}
      <Flex direction='column'>
        <Text fontSize='sm' color={textColor} fontWeight='bold'>
          {props.name}{" "}
        </Text>
        <Text fontSize='xs' color='gray.500' fontWeight='400'>
          {props.email}
        </Text>
      </Flex>
    </Flex>
    <Button p='0px' bg='transparent' variant='no-hover'>
      <Text
        fontSize='sm'
        fontWeight='600'
        color='grey.300'
        alignSelf='center'>
        {props.role}
      </Text>
    </Button>
  </Flex>)


};

export default TeamMember;
