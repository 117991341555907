// Chakra imports
import { Flex } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { tablesTableData, dashboardTableData } from "variables/general";
import { Fn } from "services/functions";
import { set } from "lodash";
import Connectors from "./components/Connectors";

function Connections() {

  const [companyConnectors, setCompanyConnectors] = React.useState([]);
  const [availableConnectors, setAvailableConnectors] = React.useState([]);

  useEffect(() => {
    Fn.connectors().then((res) => {
      const y = _.filter(res.data, (x) => {
        return x.company_connector_settings[0]
      })
      setCompanyConnectors(y);
      const z = _.filter(res.data, (x) => {
        return !x.company_connector_settings[0]
      })
      setAvailableConnectors(z);

    });
  }, []);
  return (
    <Flex direction='column' pt={{ base: "75px", md: "15px" }}>
      <Connectors
        title={"Active Connectors"}
        captions={["Connection", "Category", "Status", "Action"]}
        data={companyConnectors}
      />
      {/* <div style={{
        marginTop: 20
      }}>
        <Connectors
          title={"Available Connectors"}
          captions={["Connection", "Category", "Status", "Action"]}
          data={availableConnectors}
        /></div> */}
    </Flex>
  );

}

export default Connections;
