import {
  Avatar,
  Badge,
  Button,
  Flex,
  Td,
  Text,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { Bucket } from "services/storage";

function ClientsTableRow(props) {
  const { name, id } = props;
  const [logoUrl, setLogoUrl] = React.useState(null);

  const textColor = useColorModeValue("gray.700", "white");
  let bgStatus = useColorModeValue("gray.400", "#1a202c");
  let colorStatus = useColorModeValue("white", "gray.400");

  return (
    <Tr>
      <Td minWidth={{ sm: "250px" }} pl="0px">
        <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
          <Avatar src={logoUrl} w="50px" h="50px" borderRadius="8px" me="18px" />
          <Flex direction="column">
            <Text
              fontSize="md"
              color={textColor}
              fontWeight="bold"
              minWidth="100%"
            >
              {name}
            </Text>
            <Text fontSize="sm" color="teal.400" fontWeight="bold"
              onClick={() => {
                window.open(url, "_blank");
              }}
            >
              ID: {id}
            </Text>
          </Flex>
        </Flex>
      </Td>

    </Tr>
  );
}

export default ClientsTableRow;
