// Chakra imports
import { Button, Flex, Icon, Link, Text, useColorModeValue } from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import React from "react";
import { FaFacebook, FaInstagram, FaTwitter } from "react-icons/fa";
import { CompanyProfile } from "./CompanyProfile";

const ProfileInformation = ({
  title,
  image,
  name,
  mobile,
  address,
  location,
}) => {
  // Chakra color mode
  const textColor = useColorModeValue("gray.700", "white");

  return (
    <Card p='16px' my={{ sm: "24px", xl: "0px" }}>
      <CardHeader p='12px 5px' mb='12px'>
        <Text fontSize='lg' color={textColor} fontWeight='bold'>
          {title}
        </Text>
      </CardHeader>
      <CardBody px='5px'>
        <Flex direction='column' width={"100%"}>
          {/* <div style={{ paddingBottom: 24 }}>
            {image ? (
              <img src={image} alt='profile' width={"80%"} height={"auto"} />) : (
              <Button width="100%" >
                Add Logo
              </Button>
            )}
          </div> */}
          <Flex align='center' mb='18px'>
            <Text fontSize='md' color={textColor} fontWeight='bold' me='10px'>
              Full Name:{" "}
            </Text>
            <Text fontSize='md' color='gray.500' fontWeight='400'>
              {name}
            </Text>
          </Flex>
          <Flex align='center' mb='18px'>
            <Text fontSize='md' color={textColor} fontWeight='bold' me='10px'>
              Mobile:{" "}
            </Text>
            <Text fontSize='md' color='gray.500' fontWeight='400'>
              {mobile}
            </Text>
          </Flex>
          <Flex align='center' mb='18px'>
            <Text fontSize='md' color={textColor} fontWeight='bold' me='10px'>
              Address:{" "}
            </Text>
            <Text fontSize='md' color='gray.500' fontWeight='400'>
              {address}
            </Text>
          </Flex>
          <Flex align='center' mb='18px'>
            <Text fontSize='md' color={textColor} fontWeight='bold' me='10px'>
              Location:{" "}
            </Text>
            <Text fontSize='md' color='gray.500' fontWeight='400'>
              {location}
            </Text>
          </Flex>

        </Flex>
      </CardBody>
      <CompanyProfile

      />
    </Card>
  );
};

export default ProfileInformation;
