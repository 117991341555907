// Chakra imports
import {
  Flex,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import TablesProjectRow from "components/Tables/TablesProjectRow";
import React from "react";
import TablesLogRow from "./TablesLogRow";

const ActivityLog = ({ title, captions, data, id }) => {


  const textColor = useColorModeValue("gray.700", "white");
  return (
    <Card my='22px' overflowX={{ sm: "scroll", xl: "hidden" }}>
      <CardHeader p='6px 0px 22px 0px'>
        <Flex direction='column'>
          <Text fontSize='lg' color={textColor} fontWeight='bold' pb='.5rem'>
            Logs
          </Text>
        </Flex>
      </CardHeader>
      <CardBody>
        <Table variant='simple'>
          <Thead>
            <Tr>
              {captions.map((caption, index) => (
                <Th key={index}>{caption}</Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {data && data.activity_log && _.orderBy(data.activity_log, "created_at", "desc").map((log, index) => (
              <TablesLogRow
                log={log}
              />
            ))}
          </Tbody>
        </Table>
      </CardBody>
    </Card>
  );
};

export default ActivityLog;
