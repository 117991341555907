import React from "react"
import Db, { supabase } from "./db"

const imagesBucket = "images"


export const Bucket = {
    async uploadUserProfile(event) {
        const userId = Db.user.getUser().id
        const file = event.target.files[0]
        const fileExt = file.name.split('.').pop()
        return await supabase
            .storage
            .from(imagesBucket)
            .upload(crypto.randomUUID() + "." + fileExt.toLowerCase(), file, {
                cacheControl: '3600',
                upsert: false
            }).then(async (res) => {
                return await Db.user.updateUserData({ image: res.data.path }).then((p) => {
                    console.log("p", p);
                    return p;
                }
                );
            })
    },
    async getUrl(path) {
        let url = localStorage.getItem(path);
        if (url == null || url == undefined || url == "" || url == "null" || url == "undefined" || url == "[object Object]" || url == "UNDEFINED") {
            url = await supabase.storage.from(imagesBucket).getPublicUrl(path).data.publicUrl;
            localStorage.setItem(path, url)
        }
        return url
    }
}
