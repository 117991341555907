// Chakra imports
import { Flex, Grid, useColorModeValue } from "@chakra-ui/react";
import avatar4 from "assets/img/avatars/avatar4.png";
import ProfileBgImage from "assets/img/ProfileBackground.png";
import React from "react";
import Header from "./components/Header";
import ProfileInformation from "./components/ProfileInformation";
import { useRecoilState } from "recoil";
import { companyState } from "services/states";
import Db from "services/db";
import { userState } from "services/states";
import { Bucket } from "services/storage";
import { profileState } from "services/states";
import { profileImageState } from "services/states";
import Team from "./components/Team";
import BillingInformation from "../Billing/components/BillingInformation";
import { billingData } from "variables/general";
import NeededDocs from "./components/NeededDocs";

function Profile() {
  const [company, setCompany] = useRecoilState(companyState);
  const [user, setUser] = useRecoilState(userState);
  const [profileImage, setProfileImage] = useRecoilState(profileImageState);
  const [profile, setProfile] = useRecoilState(profileState);

  // Chakra color mode
  const textColor = useColorModeValue("gray.700", "white");
  const bgProfile = useColorModeValue(
    "hsla(0,0%,100%,.8)",
    "linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)"
  );



  return (
    <Flex direction='column'>
      <Header
        backgroundHeader={ProfileBgImage}
        backgroundProfile={bgProfile}
        avatarImage={profileImage}
        name={profile?.name}
        subtitle={user?.email}

      />
      <Grid templateColumns={{ sm: "1fr", xl: "repeat(3, 1fr)" }} gap='22px'>
        {/* <PlatformSettings
          title={"Notifications"}
          subtitle1={"ACCOUNT"}
          subtitle2={"COMPANY"}
        /> */}
        <ProfileInformation
          title={"Company Info"}
          name={company?.name}
          mobile={company?.primary_phone}
          address={company?.address}
          location={company?.city + ", " + company?.state + " " + company?.postal_code}
          image={company && company.logo && Bucket.getUrl(company.logo)}
        />
        <Team title={"Team Members"} />
        <NeededDocs title={"Docs"} />
        {/* <BillingInformation title={"Billing Information"} data={billingData} /> */}
      </Grid>
    </Flex>
  );
}

export default Profile;
