import React from "react";
import { createClient } from "@supabase/supabase-js";

const dbHost = "https://ltzrvqfkltcburogicgj.supabase.co";
const anonKey =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx0enJ2cWZrbHRjYnVyb2dpY2dqIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTQxMDg0NjYsImV4cCI6MjAwOTY4NDQ2Nn0.enSNMFf0Ye2dpGZw4HyIelTsnBKexwi-gFU_cQ6Rgv0";

export const supabase = createClient(dbHost, anonKey);

const Db = {
  user: {
    async signUp(email, password, name) {
      const { data, error } = await supabase.auth.signUp({
        email: email,
        password: password,
        options: {
          data: {
            name: name,
          }
        }
      });
      if (error) {
        console.log("signUp", error);
      }
      return data;
    },
    async signIn(email, password) {
      const { data, error } = await supabase.auth.signInWithPassword({
        email: email,
        password: password,
      });
      if (error) {
        console.log(error);
      }
      return data;
    },
    async signOut() {
      const { error } = await supabase.auth.signOut();
      if (error) {
        console.log(error);
      }
    },
    async getUser() {
      return await supabase.auth.getUser();
    },
    async getProfile() {
      const u = await this.getUser();
      return await supabase.from("user_profile").select("*").eq("user_id", u.data.user.id).then(async (res) => {
        if (res.data && res.data.length != 0) {
          if (u.data.user.email != res.data[0].email) {
            await supabase.from("user_profile").update({ email: u.data.user.email }).eq("user_id", u.data.user.id);
          }
          return res.data[0];
        } else {
          // create profile
          return await supabase.from("user_profile").insert([
            { user_id: u.data.user.id, name: u.data.user.name, email: u.data.user.email }
          ]).then((res) => {
            console.log("Created New Profile");
            return res.data;
          });
        }
      });
    },
    async verify() {
      return await supabase.auth.getSession();
    },
    async updateUserData(meta) {
      const u = await this.getUser();
      return await supabase.from("user_profile").update({ ...meta }).eq("user_id", u.data.user.id);
    }
  },
  companies: {
    async getCompanies() {
      const { data, error } = await supabase.from("company_users").select("company_id, status, id");
      if (error) {
        console.log(error);
      }
      return data;
    },
    async getCompany(companyId) {
      const { data, error } = await supabase
        .from("companies")
        .select("*")
        .eq("id", companyId);
      if (error) {
        console.log(error);
      }
      return data;
    },
    // set company_user status to accepted
    async acceptCompany(company_id) {
      const { data, error } = await supabase
        .from("company_users")
        .update({ status: "accepted" })
        .eq("company_id", company_id);
      if (error) {
        console.log(error);
      }
      return data;
    },


  },
};

export default Db;
