// import
import Dashboard from "views/Dashboard/Dashboard";
import Tables from "views/Dashboard/Activities";
import Billing from "views/Dashboard/Billing";
import RTLPage from "views/Dashboard/RTL";
import Profile from "views/Dashboard/Profile";
import SignIn from "views/Auth/SignIn.js";
import SignUp from "views/Auth/SignUp.js";

import {
  HomeIcon,
  StatsIcon,
  CreditIcon,
  PersonIcon,
  DocumentIcon,
  RocketIcon,
  SupportIcon,
} from "components/Icons/Icons";
import { BsGearFill, BsPeopleFill } from "react-icons/bs";
import Connections from "views/Dashboard/Connections";
import Clients from "views/Dashboard/Clients";
import Activity from "views/Dashboard/Activity";

var dashRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "",
    icon: <HomeIcon color="inherit" />,
    component: Dashboard,
    layout: "/admin",
  },
  {
    path: "/activities",
    name: "Activity",
    rtlName: "",
    icon: <StatsIcon color="inherit" />,
    component: Tables,
    layout: "/admin",
  },
  {
    path: "/activity/:id",
    name: "Activity",
    rtlName: "",
    icon: <StatsIcon color="inherit" />,
    component: Activity,
    layout: "/admin",
    visible: false,
  },

  {
    path: "/clients",
    name: "Clients",
    rtlName: "",
    icon: <BsPeopleFill color="inherit" />,
    component: Clients,
    layout: "/admin",
    visible: false,
  },

  {
    path: "/signin",
    name: "Sign In",
    rtlName: "",
    icon: <DocumentIcon color="inherit" />,
    component: SignIn,
    layout: "/auth",
    visible: false,
  },
  {
    path: "/signup",
    name: "Sign Up",
    rtlName: "",
    icon: <RocketIcon color="inherit" />,
    secondaryNavbar: true,
    component: SignUp,
    layout: "/auth",
    visible: false,
  },

  {
    name: "ADMIN",
    category: "account",
    rtlName: "",
    state: "pageCollapse",
    views: [

      {
        path: "/connections",
        name: "Connections",
        rtlName: "",
        icon: <BsGearFill color="inherit" />,
        component: Connections,
        layout: "/admin",
      },
      {
        path: "/profile",
        name: "Profile",
        rtlName: "",
        icon: <PersonIcon color="inherit" />,
        secondaryNavbar: true,
        component: Profile,
        layout: "/admin",
      },
      {
        path: "/billing",
        name: "Billing",
        rtlName: "",
        icon: <CreditIcon color="inherit" />,
        component: Billing,
        layout: "/admin",
        visible: false,
      },
    ],
  },
];
export default dashRoutes;
