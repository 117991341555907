// Chakra imports
import {
  Avatar,
  Button,
  Flex,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

// Custom components
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import { functions } from "lodash";
import React from "react";
import { useRecoilState } from "recoil";
import { teamState } from "services/states";
import { Bucket } from "services/storage";
import TeamMember from "./TeamMember";

const Team = ({ title }) => {
  const [team, setTeam] = useRecoilState(teamState);
  // Chakra color mode
  const textColor = useColorModeValue("gray.700", "white");


  return (
    <Card p='16px'>
      <CardHeader p='12px 5px' mb='12px'>
        <Text fontSize='lg' color={textColor} fontWeight='bold'>
          {title}
        </Text>
      </CardHeader>
      <CardBody px='5px'>
        <Flex direction='column' w='100%'>
          {team && team.length > 0 ? team.map((member, index) => (
            <TeamMember key={member.id} name={member.name} email={member.email} role={member.current_role_id?.name} image={member.image} />
          )) : <Text fontSize='md' color='gray.500' fontWeight='400'>
            No team members yet
          </Text>}



        </Flex>
      </CardBody>
    </Card>
  );
};

export default Team;
