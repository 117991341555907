import Db, { supabase } from "./db"

export const Fn = {
    async team() {
        return await supabase.functions.invoke('team', {
            body: { name: 'Functions' },
        })
    },
    async connectors() {
        return await supabase.functions.invoke('connectors', {})
    },
    // clients
    async clients() {
        return await supabase.functions.invoke('clients', {})
    },
    // activities
    async activities() {
        return await supabase.functions.invoke('activities', {})
    },
    // activity
    async activity(id) {
        return await supabase.functions.invoke('activity', {
            body: { activity: id },
        })
    },
    async dashboard() {
        return await supabase.functions.invoke('dashboard', {})
    },
    async credible_get_planner() {
        return await supabase.functions.invoke('credible_get_planner', {})
    },
    async verify_pending() {
        return await supabase.functions.invoke('verify_pending', {})
    }


}