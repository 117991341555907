// Chakra imports
import { Flex } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { Fn } from "services/functions";
import ClientList from "./components/ClientList";

function Clients() {
  const [clients, setClients] = React.useState([]);
  useEffect(() => {
    Fn.clients().then((res) => {
      console.log("clients", res.data);
      setClients(res.data);
    });
  }, []);

  return (
    <Flex direction='column' pt={{ base: "75px", md: "15px" }}>
      <ClientList
        title={"Active Connectors"}
        captions={["Connection"]}
        data={clients}
      />
      <div style={{
        marginTop: 20
      }}>
      </div>
    </Flex>
  );

}

export default Clients;
