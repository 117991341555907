// Chakra imports
import {
  Avatar,
  Button,
  Flex,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

// Custom components
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import { functions } from "lodash";
import React from "react";
import { useRecoilState } from "recoil";
import { teamState } from "services/states";
import { Bucket } from "services/storage";
import TeamMember from "./TeamMember";
import NeededDoc from "./NeededDoc";

const NeededDocs = ({ title }) => {
  // const [team, setTeam] = useRecoilState(teamState);
  // Chakra color mode
  const textColor = useColorModeValue("gray.700", "white");
  const [docs, setDocs] = React.useState([
    {
      id: 1,
      name: "Business Associate Agreement",
      description: "Needed for HIPAA compliance",
      status: "Completed",
      color: "green.300",
      icon: "check",

    }
  ]);


  return (
    <Card p='16px'>
      <CardHeader p='12px 5px' mb='12px'>
        <Text fontSize='lg' color={textColor} fontWeight='bold'>
          {title}
        </Text>
      </CardHeader>
      <CardBody px='5px'>
        <Flex direction='column' w='100%'>
          {docs && docs.length > 0 ? docs.map((doc, index) => (
            <NeededDoc key={doc.id} name={doc.name} description={doc.description} status={doc.status} color={doc.color} icon={doc.icon} link={doc.link} />
          )) : <Text fontSize='md' color='gray.500' fontWeight='400'>
            No docs yet
          </Text>}

        </Flex>
      </CardBody>
    </Card>
  );
};

export default NeededDocs;
