// Chakra imports
import {
  Avatar,
  Flex,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import TablesProjectRow from "components/Tables/TablesProjectRow";
import React from "react";

const ActivityDetails = ({ title, captions, data, id }) => {


  const textColor = useColorModeValue("gray.700", "white");
  return (
    <Card my='22px' overflowX={{ sm: "scroll", xl: "hidden" }}>
      <CardHeader p='6px 0px 22px 0px'>
        <Flex direction='column'>
          <Text fontSize='lg' color={textColor} fontWeight='bold' pb='.5rem'>
            Details
          </Text>
        </Flex>
      </CardHeader>
      <CardBody>
        <div>
          <div style={{ width: "100%", display: "flex" }}>
            <Avatar name={data.provider_id?.name} src={data.provider_id?.logo} />
            <div style={{
              fontSize: 20,
              fontWeight: "bold",
              paddingLeft: 10,
              paddingTop: 9,
              flex: 1
            }}>
              <Text fontSize='lg' color={textColor} pb='.5rem'>
                {data.provider_id?.name}
              </Text>
            </div>

          </div>
          <div style={{ marginTop: 10 }}>
            <Text fontSize='lg' color={textColor} fontWeight='bold' pb='.5rem'>
              Client
            </Text>
            {data.client_id?.name}
          </div>
          <div style={{ marginTop: 10 }}>
            <Text fontSize='lg' color={textColor} fontWeight='bold' pb='.5rem'>
              Connector
            </Text>
            {data.connector_id?.name}
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default ActivityDetails;
