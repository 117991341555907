import { SearchIcon } from "@chakra-ui/icons";
import { Button, ButtonGroup, IconButton, Input, InputGroup, InputLeftElement } from "@chakra-ui/react";
import { PersonIcon } from "components/Icons/Icons";
import React, { useEffect } from "react";
import { BsUpload } from "react-icons/bs";
import Db from "services/db";
import { Bucket } from "services/storage";

export function UploadProfile(props) {

    const [name, setName] = React.useState(props.name);




    return (
        <div style={{
            visibility: props.show ? "visible" : "hidden",
            width: "100vw",
            height: "100vh",
            position: "fixed",
            top: 0,
            left: 0,
            zIndex: 1000,

        }}

        ><div
            style={{
                marginTop: "10vh",
                width: 400,
                height: 400,
                backgroundColor: "#F7FAFC",
                borderRadius: 10,
                zIndex: 9000,
                padding: 20,
                position: "relative",
            }}>

                <div style={{
                    width: 160,
                    height: 160,
                    backgroundColor: "white",
                    borderRadius: 10,
                    backgroundImage: `url(` + props.image + `)`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    position: "relative",
                    overflow: "hidden",
                    margin: "auto",
                    border: "2px solid #000",
                }}>
                    <div
                        style={{
                            width: 160,
                            height: 160,
                            backgroundColor: "rgba(0,0,0,0.2)",
                            borderRadius: 10,
                            position: "absolute",
                            top: 0,
                            left: 0,
                            textAlign: "center",
                            fontWeight: 900,
                            color: "white",
                            fontSize: 80,
                            paddingTop: 40,
                        }}
                    >
                        <BsUpload style={{ marginLeft: "auto", marginRight: "auto" }} />
                    </div>
                    <input style={{
                        width: "100%",
                        height: "100%",
                        border: "none",
                        position: "absolute",
                        top: 0,
                        left: 0,
                        backgroundColor: "white",
                        opacity: 0,

                    }} type="file" onChange={(e) => {


                        Bucket.uploadUserProfile(e).then(async (res) => {
                            console.log(res);
                            props.onClose();
                        })
                    }
                    } />
                </div>
                <div style={{
                    paddingTop: 20,
                    paddingBottom: 20,
                    textAlign: "left "
                }}>
                    <div style={{
                        marginTop: 10,
                        fontWeight: "bold",
                        borderTop: "3px solid #ccc",
                        paddingTop: 10,
                    }}>
                        Full Name </div>
                    <InputGroup
                        cursor="pointer"
                        borderRadius="15px"

                        me={{ sm: "auto", md: "20px" }}

                    >
                        <InputLeftElement
                            children={<PersonIcon w="15px" h="15px" />

                            }
                        />
                        <Input
                            fontSize="xs"
                            py="11px"
                            value={name}
                            placeholder="Type full name..."
                            borderRadius="inherit"
                            onChange={(e) => setName(e.target.value)}
                        />
                    </InputGroup>
                </div>
                <div style={{
                    marginLeft: "auto",
                    display: "flex",
                    justifyContent: "space-between",
                    textAlign: "left"
                }}>
                    <div style={{ flex: 1 }}>
                        <Button
                            colorScheme="teal"
                            onClick={() => {
                                Db.user.updateUserData({ name }).then((res) => {
                                    console.log(res);
                                    props.onClose();
                                });
                            }}
                        > Save Details</Button>

                    </div>
                    <div>
                        <Button onClick={() => {
                            props.onClose();
                        }}>Cancel</Button>
                    </div>
                </div>
            </div>

            <div style={{
                width: "100vw",
                height: "100vh",
                position: "fixed",
                top: 0,
                left: 0,
                zIndex: -1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "rgba(0,0,0,0.2)",
            }} onClick={() => props.onClose()}>
            </div>

        </div>
    )
}