// Chakra imports
import { Flex } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { Fn } from "services/functions";
import Activities from "./components/Activities";

function Tables() {
  const [activities, setActivities] = React.useState([]);
  useEffect(() => {
    Fn.activities().then((res) => {
      console.log("activities", res.data);
      setActivities(res.data);
    });



  }, []);

  return (
    <Flex direction='column' pt={{ base: "75px", md: "15px" }}>
      {activities && activities.length > 0 && (
        <Activities
          title={"Activity"}
          captions={["Activity", "Date", "Status"]}
          data={activities}
        />)}
    </Flex>
  );
}

export default Tables;
