import {
  Avatar,
  Badge,
  Button,
  Flex,
  Td,
  Text,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { Bucket } from "services/storage";

function TablesConnectorRow(props) {
  const { logo, name, url, category, status, description, slug, settings } = props;
  const [logoUrl, setLogoUrl] = React.useState(null);

  const textColor = useColorModeValue("gray.700", "white");
  let bgStatus = useColorModeValue("gray.400", "#1a202c");
  let colorStatus = useColorModeValue("white", "gray.400");

  if (settings ? settings.status : status === "Online") {
    colorStatus = useColorModeValue("white", "gray.400");
    bgStatus = useColorModeValue("green.400", "#1a202c");
  }

  useEffect(() => {
    Bucket.getUrl(logo).then((url) => {
      setLogoUrl(url);
    });
  }, [logo]);

  return (
    <Tr>
      <Td minWidth={{ sm: "250px" }} pl="0px">
        <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
          <Avatar style={{ backgroundColor: "white" }} src={logoUrl} w="50px" h="50px" borderRadius="8px" me="18px" />
          <Flex direction="column">
            <Text
              fontSize="md"
              color={textColor}
              fontWeight="bold"
              minWidth="100%"
            >
              {name}
            </Text>
            <Text fontSize="sm" color="gray.400" fontWeight="normal">
              {description}
            </Text>

            <Text fontSize="sm" color="teal.400" fontWeight="bold"
              onClick={() => {
                window.open(url, "_blank");
              }}
            >
              {url && "link"}
            </Text>
          </Flex>
        </Flex>
      </Td>

      <Td>
        <Flex direction="column">
          <Text fontSize="md" color={textColor} fontWeight="bold">
            {category}
          </Text>
        </Flex>
      </Td>
      <Td>
        <Badge
          bg={status === "Online" ? "green.400" : bgStatus}
          color={status === "Online" ? "white" : colorStatus}
          fontSize="16px"
          p="3px 10px"
          borderRadius="8px"
        >
          {settings ? settings.status : status}
        </Badge>
      </Td>

      <Td>
        <Button p="0px" bg="transparent" variant="no-hover"
          onClick={() => {
            window.location.href = `#/${slug}`;
          }}
        >
          <Text
            fontSize="md"
            color="gray.400"
            fontWeight="bold"
            cursor="pointer"
          >
            {settings ? "Edit" : "View"}
          </Text>
        </Button>
      </Td>
    </Tr>
  );
}

export default TablesConnectorRow;
