// Chakra imports
import {
  Button,
  Flex,
  Grid,
  Image,
  SimpleGrid,
  useColorModeValue,
} from "@chakra-ui/react";
// assets
import peopleImage from "assets/img/people-image.png";
import logoChakra from "assets/img/people-image.png";
import BarChart from "components/Charts/BarChart";
import LineChart from "components/Charts/LineChart";
// Custom icons
import {
  CartIcon,
  DocumentIcon,
  GlobeIcon,
  WalletIcon,
} from "components/Icons/Icons.js";
import React, { useEffect } from "react";
import { dashboardTableData, timelineData } from "variables/general";
import ActiveUsers from "./components/ActiveUsers";
import BuiltByDevelopers from "./components/BuiltByDevelopers";
import MiniStatistics from "./components/MiniStatistics";
import OrdersOverview from "./components/OrdersOverview";
import Projects from "./components/Projects";
import SalesOverview from "./components/SalesOverview";
import WorkWithTheRockets from "./components/WorkWithTheRockets";
import { CheckCircleIcon, CloseIcon, NotAllowedIcon } from "@chakra-ui/icons";
import { IoNotifications, IoNotificationsCircleOutline, IoPhonePortraitOutline, IoRefresh } from "react-icons/io5";
import { BsPeople, BsPeopleFill } from "react-icons/bs";
import { Fn } from "services/functions";
import DayStatistics from "./components/DayStatistics";
import moment from "moment";
import { FaChartBar } from "react-icons/fa";

export default function Dashboard() {
  const [activities, setActivities] = React.useState([]);
  const [loadingRefresh, setLoadingRefresh] = React.useState(false);
  const [laodingFakeData, setLaodingFakeData] = React.useState(false);
  const iconBoxInside = useColorModeValue("white", "white");

  useEffect(() => {

    Fn.dashboard().then((res) => {
      console.log("dashboard", res.data);
      setActivities(res.data);
    });

    // let dashboardInterval = setInterval(() => {
    //   Fn.dashboard().then((res) => {
    //     console.log("dashboard", res.data);
    //     setActivities(res.data);
    //   });
    // }, 30 * 1000);

    return () => {
      // console.log("clearing dashboard interval");
      // clearInterval(dashboardInterval);
    }
  }, []);

  return (
    <Flex flexDirection='column' pt={{ base: "75px", md: "15px" }}>
      <div style={{
        paddingLeft: 10,
        fontSize: 14,
        fontWeight: 600,
      }}>
        {/* Last 7 Days */}
      </div>
      {/* <SimpleGrid columns={{ sm: 1, md: 2, xl: 4 }} spacing='24px'>
        <MiniStatistics
          title={"Pending"}
          amount={stats?.pending}
          color={"primary"}
          percentage={8}
          helpText={"of normal weekly avg"}
          icon={<BsPeopleFill h={"24px"} w={"24px"} color={iconBoxInside} />}
        />

        <MiniStatistics
          title={"Denied"}
          amount={stats?.denied}
          color={"danger"}
          percentage={-10}
          helpText={"of normal daily avg"}
          icon={<NotAllowedIcon h={"24px"} w={"24px"} color={iconBoxInside} />}
        />
        <MiniStatistics
          title={"Approved"}
          amount={stats?.approved}
          percentage={10}
          helpText={"of normal daily avg"}
          icon={<CheckCircleIcon h={"24px"} w={"24px"} color={iconBoxInside} />}
        />

        <MiniStatistics
          title={"Total"}
          amount={stats?.total}
          color={"info"}
          percentage={14}
          helpText={"of normal weekly avg"}
          icon={<DocumentIcon h={"24px"} w={"24px"} color={"white"} />}
        />
      </SimpleGrid> */}
      <div style={{ paddingTop: 20, fontWeight: 900, display: "flex" }}>
        <div style={{ flex: 1 }}>
          Upcoming (7 Days)
        </div>
        <div>
          <Button
            onClick={() => {
              setLoadingRefresh(true);
              Fn.verify_pending().then((res) => {

                Fn.dashboard().then((res) => {
                  console.log("dashboard", res.data);
                  setActivities(res.data);
                  setTimeout(() => {
                    setLoadingRefresh(false);
                  }, 500);
                });
              });
            }}
            disabled={loadingRefresh}
            style={{ marginLeft: 10 }} size="xs" variant="outline" colorScheme="teal" leftIcon={<IoRefresh />}>{loadingRefresh ? "Loading" : "Refresh"}</Button>

          <Button
            onClick={() => {
              setLaodingFakeData(true);
              Fn.credible_get_planner().then((res) => {
                Fn.dashboard().then((res) => {
                  console.log("dashboard", res.data);
                  setActivities(res.data);
                  setTimeout(() => {
                    setLaodingFakeData(false);
                  }, 500);
                });
              });
            }}
            disabled={laodingFakeData}
            style={{ marginLeft: 10 }} size="xs" variant="outline" colorScheme="teal" leftIcon={<FaChartBar />}>{laodingFakeData ? "Loading..." : "Generate Fake Data"}</Button>
        </div>
      </div>
      <SimpleGrid style={{ marginTop: 10 }} columns={{ sm: 1, md: 2, xl: 7 }} spacing='24px'>
        <DayStatistics
          title={moment().format("dddd MM/D") + " (Today)"}
          activities={activities.day_0}
        />
        <DayStatistics
          title={moment().add(1, "days").format("dddd MM/D")}
          activities={activities.day_1}
        />
        <DayStatistics
          title={moment().add(2, "days").format("dddd MM/D")}
          activities={activities.day_2}
        />
        <DayStatistics
          title={moment().add(3, "days").format("dddd MM/D")}
          activities={activities.day_3}
        />
        <DayStatistics
          title={moment().add(4, "days").format("dddd MM/D")}
          activities={activities.day_4}
        />
        <DayStatistics
          title={moment().add(5, "days").format("dddd MM/D")}
          activities={activities.day_5}
        />
        <DayStatistics
          title={moment().add(6, "days").format("dddd MM/D")}
          activities={activities.day_6}
        />

      </SimpleGrid>
      {/* <Grid
        templateColumns={{ md: "1fr", lg: "1.8fr 1.2fr" }}
        templateRows={{ md: "1fr auto", lg: "1fr" }}
        my='26px'
        gap='24px'>
        <BuiltByDevelopers
          title={"Notifications Manager"}
          name={"Setup SMS and email notifications"}
          description={
            "Valid is even more powerful with mobile SMS alerts setup, get instant alerts when a claim is denied or set a weekly email digest"
          }
          image={
            <IoNotificationsCircleOutline color="white" size={120} />
          }
        />
        <WorkWithTheRockets
          backgroundImage={peopleImage}
          title={"Getting started with Valid."}
          description={
            "Getting started with Valid is easy.  To make it even easier we have a quick video that explains the whole thing in 15min."
          }
        />
      </Grid> */}
      {/* <Grid
        templateColumns={{ sm: "1fr", lg: "1.3fr 1.7fr" }}
        templateRows={{ sm: "repeat(2, 1fr)", lg: "1fr" }}
        gap='24px'
        mb={{ lg: "26px" }}>
        <ActiveUsers
          title={"Active Users"}
          percentage={23}
          chart={<BarChart />}
        />
        <SalesOverview
          title={"Sales Overview"}
          percentage={5}
          chart={<LineChart />}
        />
      </Grid> */}
      {/* 
      <Projects
        title={"Recent Activity"}
        amount={10}
        captions={["Activity", "Date", "Status"]}
        data={stats.activities}

      /> */}


    </Flex>
  );
}
