// Chakra imports
import { CheckCircleIcon, NotAllowedIcon } from "@chakra-ui/icons";
import {
  Flex,
  Stat,
  StatHelpText,
  StatLabel,
  StatNumber,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import IconBox from "components/Icons/IconBox";
import { ClockIcon } from "components/Icons/Icons";
import moment from "moment";
import React from "react";

const DayStatistics = ({ title, activities }) => {
  const iconTeal = useColorModeValue("teal.400", "teal.400");
  const iconRed = useColorModeValue("red.400", "red.300");
  const iconGrey = useColorModeValue("gray.300", "gray.300");
  const iconPrimary = useColorModeValue("blue.200", "blue.200");
  const textColor = useColorModeValue("gray.700", "white");
  const iconWarning = useColorModeValue("orange.300", "orange.400");



  function Activity({ planned_date, id, status, external_id }) {
    return (
      <div
        onClick={() => {
          window.location.href = `#/admin/activity/` + id;
        }}
        style={{ fontWeight: 900, fontSize: 16, flex: 1, display: "flex", marginBottom: 4, backgroundColor: "#eeeeee90", padding: 4, borderRadius: 16, marginBottom: 8 }}>

        {status == "pending" && (
          <IconBox style={{ marginTop: 5, marginLeft: 4 }} as='box' h={"30px"} w={"30px"} bg={iconWarning}>
            <ClockIcon h={"16px"} w={"16px"} style={{ marginLeft: 0, marginTop: 1 }} color={"white"} />
          </IconBox>)}

        {status == "approved" && (
          <IconBox style={{ marginTop: 5, marginLeft: 4 }} as='box' h={"30px"} w={"30px"} bg={iconTeal}>
            <CheckCircleIcon h={"16px"} w={"16px"} style={{ marginLeft: 0, marginTop: 1 }} color={"white"} />
          </IconBox>)}

        {status == "denied" && (
          <IconBox style={{ marginTop: 5, marginLeft: 4 }} as='box' h={"30px"} w={"30px"} bg={iconRed}>
            <NotAllowedIcon h={"16px"} w={"16px"} style={{ marginLeft: 0, marginTop: 1 }} color={"white"} />
          </IconBox>)}

        <div style={{ marginTop: -4 }}>
          &nbsp;<a href="link_to_appointment" target="_blank">{moment(planned_date).format("LT")}</a>
          <div style={{ paddingLeft: 8, color: "#777", marginTop: -4, fontSize: 12, fontWeight: 400 }}>ID:{external_id}</div></div>
      </div>
    )
  }



  return (
    <Card minH='83px'>
      <CardBody>
        <Flex flexDirection='row' align='center' justify='center' w='100%'>
          <Stat me='auto'>
            <StatLabel
              fontSize='sm'
              color='gray.400'
              fontWeight='bold'
              pb='.1rem'>
              {title}
            </StatLabel>
            <Flex direction={"column"}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                {activities && activities.length > 0 && activities.map((a, index) => {
                  return <Activity key={index} planned_date={a.planned_date} id={a.id} status={a.status} external_id={a.external_id} />
                })}

              </div>
            </Flex>
          </Stat>

        </Flex>
      </CardBody>
    </Card>
  );
};

export default DayStatistics;
