// Chakra imports
import { Flex } from "@chakra-ui/react";
import Card from "components/Card/Card";
import React from "react";


function Setup() {

    return (
        <Flex direction='column'>

            <div style={{
                marginTop: 100,
                width: 400,

                marginLeft: "auto",
                marginRight: "auto",
                borderRadius: 10,
                textAlign: "center",
            }}>
                <Card>
                    <div style={{
                        padding: 20,
                        fontSize: 24,
                        fontWeight: 700,

                    }}>
                        No Company Profile
                    </div>
                    <div style={{
                        padding: 20,
                        fontSize: 16,
                        fontWeight: 500,
                        color: "gray",
                    }}>
                        We did not find a company profile for this account. Please contact your administrator.
                    </div>
                    <div style={{
                        padding: 0,
                        fontSize: 16,
                        fontWeight: 500,
                        color: "gray",
                    }} >
                        or
                    </div>
                    <div style={{
                        padding: 20,
                        fontSize: 16,
                        fontWeight: 500,
                        color: "gray",
                    }}>
                        contact <a href="mailto:support@valid.care"> support@valid.care</a>
                    </div>
                </Card>

            </div >
        </Flex >
    );
}

export default Setup;
